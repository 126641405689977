export default function Success() {
    return (
        <section>
            <h1>Quiz Sent!</h1>
            <p>
                We'll let you know when it's complete. In the mean time, feel
                free to send another quiz.
            </p>
            <a href="/">
                <button className="call-to-action">Send Another Quiz</button>
            </a>
        </section>
    );
}
