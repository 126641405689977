import React from "react";

interface Props {
    setShowQuiz: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Start({ setShowQuiz }: Props) {
    return (
        <section id="hero">
            <h1>Someone wants to find a gift for you.</h1>
            <p>Complete this short quiz to help them out.</p>
            <button
                className="call-to-action"
                onClick={() => setShowQuiz(true)}
            >
                Get Started
            </button>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            ></div>
        </section>
    );
}
