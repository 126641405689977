import { useState } from "react";
import Header from "./Header";
import Hero from "./Hero";
import Start from "./Start";
import Quiz from "./Quiz";
import Products from "./Products";
import Results from "./Results";

import { Routes, Route } from "react-router-dom";

function App() {
    const [showQuiz, setShowQuiz] = useState(false);
    // TODO create SenderQuiz and RecipientQuiz components that extend Quiz
    // Within these components, import svgs as react components to prevent lag
    // Move svgs to within src so they can be imported
    return (
        <div className="app">
            {!showQuiz && <Header />}
            <Routes>
                <Route
                    path="/"
                    element={
                        showQuiz ? (
                            <Quiz
                                setShowQuiz={setShowQuiz}
                                firstQuestionId={1}
                            />
                        ) : (
                            <Hero setShowQuiz={setShowQuiz} />
                        )
                    }
                />
                <Route
                    path="/:id"
                    element={
                        showQuiz ? (
                            <Quiz
                                setShowQuiz={setShowQuiz}
                                firstQuestionId={7}
                            />
                        ) : (
                            <Start setShowQuiz={setShowQuiz} />
                        )
                    }
                />
                <Route path="/popular" element={<Products />} />
                <Route path="/:id/results" element={<Results />} />
            </Routes>
        </div>
    );
}

export default App;
