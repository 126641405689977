import React from "react";

export default function Thanks() {
    return (
        <>
            <h1>That's it!</h1>
            <p>
                You just made gifting a little easier. Try it for yourself and
                send your own quiz!
            </p>
            <a href="/">
                <button className="call-to-action">Send A Quiz</button>
            </a>
        </>
    );
}
