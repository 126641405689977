import { results } from "../data";

export default function Products() {
    return (
        <section id="product-list">
            <h1>Popular Gifts</h1>
            {results[0].products.map((e, i) => {
                return (
                    <div key={i}>
                        <div className="product">
                            <a href={e.url}>
                                <img src={e.img} alt="" />
                            </a>
                            <div className="product-info">
                                <h4>{e.name}</h4>
                                <p>{e.price}</p>
                            </div>
                        </div>
                    </div>
                );
            })}
        </section>
    );
}
