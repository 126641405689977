import { IOption, IQuestion, IResult } from "./types";

export const getQuestion = (questionId: number) => {
    return questions.find((x) => x.id === questionId);
};

export const getOptions = (optionIds: number[] | undefined) => {
    if (optionIds) {
        return options.filter((x) => optionIds.includes(x.id));
    } else {
        return [];
    }
};

export const options: IOption[] = [
    { id: 1, text: "Family", nextQuestionId: 2, img: "family.svg" },
    { id: 2, text: "Friend", nextQuestionId: 2, img: "friend.svg" },
    { id: 3, text: "Partner", nextQuestionId: 2, img: "partner.svg" },
    {
        id: 4,
        text: "Coworker",
        nextQuestionId: 2,
        img: "coworker.svg",
    },

    { id: 5, text: "Male", nextQuestionId: 3, img: "male.svg" },
    { id: 6, text: "Female", nextQuestionId: 3, img: "female.svg" },
    { id: 7, text: "Non-Binary", nextQuestionId: 3, img: "nonbinary.svg" },

    { id: 8, text: "Adult", nextQuestionId: 4, img: "adult.svg" },
    { id: 9, text: "Teen", nextQuestionId: 4, img: "teen.svg" },
    { id: 10, text: "Child", nextQuestionId: 4, img: "child.svg" },

    { id: 11, text: "Christmas", nextQuestionId: null, img: "christmas.svg" },
    { id: 12, text: "Wedding", nextQuestionId: null, img: "wedding.svg" },
    { id: 13, text: "Birthday", nextQuestionId: null, img: "birthday.svg" },
    { id: 14, text: "Hanukkah", nextQuestionId: null, img: "hanukkah.svg" },
    {
        id: 15,
        text: "Anniversary",
        nextQuestionId: null,
        img: "anniversary.svg",
    },
    { id: 16, text: "Other", nextQuestionId: null, img: "gift.svg" },
    {
        id: 27,
        text: "Outdoors",
        nextQuestionId: 8,
        keywords: ["outdoor"],
        img: "outdoor.svg",
    },
    {
        id: 28,
        text: "Home",
        nextQuestionId: 9,
        keywords: ["homeimprovement", "diy", "tools"],
        img: "home.svg",
    },
    {
        id: 29,
        text: "Work",
        nextQuestionId: 9,
        keywords: ["work", "selfimprovement"],
        img: "coworker.svg",
    },
    {
        id: 30,
        text: "Gaming",
        nextQuestionId: 9,
        keywords: ["gaming", "tech"],
        img: "gaming.svg",
    },
    {
        id: 31,
        text: "Fitness",
        nextQuestionId: 9,
        keywords: ["active", "exercise"],
        img: "fitness.svg",
    },
    {
        id: 32,
        text: "Friends",
        nextQuestionId: 9,
        keywords: ["home", "social", "games"],
        img: "friends.svg",
    },
    {
        id: 33,
        text: "Parties",
        nextQuestionId: 9,
        keywords: ["clothing", "social", "games"],
        img: "party.svg",
    },
    {
        id: 34,
        text: "Books",
        nextQuestionId: 9,
        keywords: ["books", "games"],
        img: "book.svg",
    },
    {
        id: 35,
        text: "Family",
        nextQuestionId: 9,
        keywords: ["home", "diy", "crafts", "games"],
        img: "family.svg",
    },
    {
        id: 36,
        text: "Run",
        nextQuestionId: 9,
        keywords: ["shoes"],
        img: "run.svg",
    },
    {
        id: 37,
        text: "Bike",
        nextQuestionId: 9,
        keywords: ["bike"],
        img: "bike.svg",
    },
    {
        id: 38,
        text: "Garden",
        nextQuestionId: 9,
        keywords: ["garden"],
        img: "garden.svg",
    },
    {
        id: 39,
        text: "Shoot",
        nextQuestionId: 9,
        keywords: ["photography"],
        img: "camera.svg",
    },
    {
        id: 40,
        text: "Camp",
        nextQuestionId: 9,
        keywords: ["camping"],
        img: "camp.svg",
    },
    {
        id: 41,
        text: "Swim",
        nextQuestionId: 9,
        keywords: ["water"],
        img: "swim.svg",
    },
    {
        id: 42,
        text: "Fish",
        nextQuestionId: 9,
        keywords: ["hunting"],
        img: "fishing.svg",
    },
    {
        id: 43,
        text: "Hike",
        nextQuestionId: 9,
        keywords: ["shoes"],
        img: "outdoor.svg",
    },
    {
        id: 44,
        text: "Boat",
        nextQuestionId: 9,
        keywords: ["hunting"],
        img: "boat.svg",
    },
    {
        id: 45,
        text: "Beach",
        nextQuestionId: null,
        keywords: ["beach"],
        img: "beach.svg",
    },
    {
        id: 46,
        text: "Boat",
        nextQuestionId: null,
        keywords: ["beach"],
        img: "houseboat.svg",
    },
    {
        id: 47,
        text: "City",
        nextQuestionId: null,
        keywords: ["selfimprovement", "minimalist"],
        img: "city.svg",
    },
    {
        id: 48,
        text: "Cabin",
        nextQuestionId: null,
        keywords: ["cozy"],
        img: "cabin.svg",
    },
    {
        id: 49,
        text: "Suburbs",
        nextQuestionId: null,
        keywords: ["activewear", "garden"],
        img: "suburb.svg",
    },
    {
        id: 50,
        text: "Treehouse",
        nextQuestionId: null,
        keywords: ["adventure", "outdoor", "camping"],
        img: "treehouse.svg",
    },
    {
        id: 51,
        text: "Farm",
        nextQuestionId: 4,
        keywords: ["farm", "tools", "home", "crafts"],
        img: "barn.svg",
    },
    // {
    //     id: 51,
    //     text: "Management",
    //     nextQuestionId: null,
    //     keywords: ["selfimprovement"],
    // },
    // {
    //     id: 52,
    //     text: "Business/Finance",
    //     nextQuestionId: null,
    //     keywords: ["minimalist"],
    // },
    // {
    //     id: 53,
    //     text: "Tech/Engineering",
    //     nextQuestionId: null,
    //     keywords: ["selfimprovement", "minimalist", "tech"],
    // },
    // {
    //     id: 54,
    //     text: "Manufacturing",
    //     nextQuestionId: null,
    //     keywords: ["tools", "tech"],
    // },
    // {
    //     id: 55,
    //     text: "Education",
    //     nextQuestionId: null,
    //     keywords: ["office"],
    // },
    // {
    //     id: 56,
    //     text: "Healthcare",
    //     nextQuestionId: null,
    //     keywords: ["active", "health"],
    // },
    // {
    //     id: 57,
    //     text: "Social/Community/Entertainment",
    //     nextQuestionId: null,
    //     keywords: ["home"],
    // },
    // {
    //     id: 58,
    //     text: "Construction/Maintenance/Repair",
    //     nextQuestionId: null,
    //     keywords: ["tools"],
    // },
    // {
    //     id: 59,
    //     text: "Sales/Customer Service",
    //     nextQuestionId: null,
    //     keywords: ["selfimprovement", "tech"],
    // },
    // {
    //     id: 60,
    //     text: "Other",
    //     nextQuestionId: null,
    //     keywords: ["games"],
    // },
];

export const questions: IQuestion[] = [
    {
        id: 1,
        text: "Who are you shopping for?",
        defaultNextQuestionId: 2,
        selectMultiple: false,
        optionIds: [1, 2, 3, 4],
    },
    {
        id: 2,
        text: "How do they identify?",
        selectMultiple: false,
        defaultNextQuestionId: 3,
        optionIds: [5, 6, 7],
    },
    {
        id: 3,
        text: "How old are they?",
        selectMultiple: false,
        defaultNextQuestionId: 4,
        optionIds: [8, 9, 10],
    },
    {
        id: 4,
        text: "What's the occasion?",
        selectMultiple: false,
        defaultNextQuestionId: null,
        optionIds: [11, 12, 13, 14, 15, 16],
    },
    {
        id: 7,
        text: "What's your thing?",
        selectMultiple: true,
        defaultNextQuestionId: 9,
        optionIds: [27, 28, 29, 30, 31, 32, 33, 34, 35],
    },
    {
        id: 8, // clarify outdoor activity
        text: "What do you do outdoors?",
        defaultNextQuestionId: 3,
        selectMultiple: true,
        optionIds: [36, 37, 38, 39, 40, 41, 42, 43, 44],
    },
    {
        id: 9, // determine style
        text: "Where's your dream home?",
        defaultNextQuestionId: null,
        selectMultiple: true,
        optionIds: [45, 46, 47, 48, 49, 50, 51],
    },
    // {
    //     id: 10, // determine occupation
    //     text: "What do you do?",
    //     selectMultiple: true,
    //     defaultNextQuestionId: null,
    //     optionIds: [52, 53, 54, 55, 56, 57, 58],
    // },
];

export const results: IResult[] = [
    {
        category: "Practical Gifts",
        products: [
            {
                name: "Reekbok Men's Classic",
                url: "https://amzn.to/3TLY3pO",
                img: "https://m.media-amazon.com/images/I/41p7zTWIBsL._AC_UY575_.jpg",
                keywords: ["minimalist", "male", ""],
                price: 79.99,
            },
            {
                name: "Darn Tough mens Hiker Merino Wool Micro Crew Socks Cushion",
                url: "https://amzn.to/3i27cx6",
                img: "https://m.media-amazon.com/images/I/71ZRl98TGXL._AC_SX679._SX._UX._SY._UY_.jpg",
                keywords: ["minimalist", "male", ""],
                price: 79.99,
            },
        ],
    },
];
