import { useState, useEffect } from "react";
import { results as res } from "../data";
import { IResult } from "../types";

export default function Results() {
    const [results, setResults] = useState<IResult[]>([]);
    useEffect(() => {
        // fetch from backend
        setResults(res);
    }, []);
    return (
        <section id="product-list">
            <h1>Quiz Results</h1>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officiis sed, aspernatur reiciendis sapiente maxime accusamus.
            </p>
            {results.map((r, i) => {
                return (
                    <div className="category" key={i}>
                        <h3>{r.category}</h3>
                        {r.products.map((e, i) => {
                            return (
                                <div key={i} className="product">
                                    <a href={e.url}>
                                        <img src={e.img} alt="" />
                                    </a>
                                    <div className="product-info">
                                        <h4>{e.name}</h4>
                                        <p>{e.price}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </section>
    );
}
