import React from "react";
import { Link } from "react-router-dom";

interface Props {
    setShowQuiz: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Hero({ setShowQuiz }: Props) {
    return (
        <section id="hero">
            <h1>Send a Quiz to Find the Perfect Gift.</h1>
            <p>Send an a quiz to find the perfect gift for any occasion.</p>
            <button
                className="call-to-action"
                onClick={() => setShowQuiz(true)}
            >
                Send Quiz
            </button>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <p>- or -</p>
                <Link to={"/popular"} style={{ textDecoration: "underline" }}>
                    View Popular Gifts
                </Link>
            </div>
        </section>
    );
}
