import { useState } from "react";
import axios from "axios";
import { SERVER_DOMAIN } from "../config";
import { PatternFormat } from "react-number-format";

interface Props {
    onSubmit: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function QuizSubmit({ onSubmit }: Props) {
    const [recipient, setRecipient] = useState("");
    const [sender, setSender] = useState("");
    const [recipientError, setRecipientError] = useState("");
    const [senderError, setSenderError] = useState("");
    const [isSending, setIsSending] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const isValidInput = (input: string) => {
        if (input.length === 0) {
            return true;
        }
        const digits = input.match(/\d/g) || [];
        if (digits.length === 11) {
            return true;
        }
        return false;
    };

    const validateRecipient = (value: string) => {
        if (!isValidInput(value)) {
            setRecipientError("Enter a 10 digit number.");
        } else {
            setRecipientError("");
        }
    };

    const validateSender = (value: string) => {
        if (!isValidInput(value)) {
            setSenderError("Enter a 10 digit number.");
        } else {
            setSenderError("");
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setErrorMessage("");
        validateRecipient(recipient);
        validateSender(sender);
        if (recipient.length === 0) {
            setRecipientError("Enter a 10 digit number.");
        }
        if (sender.length === 0) {
            setSenderError("Enter a 10 digit number.");
        }
        if (!recipientError && !senderError) {
            setIsSending(true);
            const jobData = {
                receiverPhone: recipient,
                senderPhone: sender,
                quizType: 1,
            };
            axios
                .post(`${SERVER_DOMAIN}/quiz`, jobData)
                .then((res: any) => {
                    setIsSending(false);
                    if (res.status === 400) {
                        setErrorMessage("Invalid Number");
                    }
                    if (res.status !== 200) {
                        setErrorMessage("Server error");
                    }
                    if (res.status === 200) {
                        onSubmit(true);
                    }
                })
                .catch((error: any) => {
                    setIsSending(false);
                    setErrorMessage("Server error");
                });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h1>Them</h1>
            <PatternFormat
                type="tel"
                format="+1 (###) ###-####"
                mask="_"
                onValueChange={(value) => setRecipient(value.formattedValue)}
                required
                placeholder="Their Phone"
            />
            {recipientError && (
                <div className="error-text">{recipientError}</div>
            )}
            <h1>You</h1>
            <PatternFormat
                type="tel"
                format="+1 (###) ###-####"
                mask="_"
                onValueChange={(value) => setSender(value.formattedValue)}
                required
                placeholder="Your Phone"
            />
            {senderError && <div className="error-text">{senderError}</div>}
            <p>
                We'll use this info to send them a short quiz. Once it's
                complete, we'll send you a list of perfect gifts.
            </p>
            <button
                type="submit"
                className="call-to-action"
                disabled={isSending}
            >
                Submit
            </button>
            {errorMessage && <p>{errorMessage}</p>}
        </form>
    );
}
