import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { getQuestion, getOptions } from "../data";
import { IAnswer, IOption, IQuestion } from "../types";
import Option from "./Option";
import Submit from "./Submit";
import Success from "./Success";
import Thanks from "./Thanks";

interface Props {
    firstQuestionId: number;
    setShowQuiz: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Quiz({ setShowQuiz, firstQuestionId }: Props) {
    const [question, setQuestion] = useState<IQuestion>();
    const [selected, setSelected] = useState<IOption>();
    const [answers, setAnswers] = useState<IAnswer[]>([]);
    const [isComplete, setIsComplete] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    let { id } = useParams();

    useEffect(() => {
        setQuestion(getQuestion(firstQuestionId));
    }, [firstQuestionId]);

    const handleBack = () => {
        const prevAnswer = answers.pop();
        if (prevAnswer) {
            setQuestion(getQuestion(prevAnswer.questionId));
        } else {
            setShowQuiz(false);
        }
        setSelected(undefined);
        setIsComplete(false);
    };

    const handleNext = () => {
        if (question) {
            if (selected) {
                // add to answers
                const newAnswer: IAnswer = {
                    questionId: question.id,
                    optionId: selected.id,
                };
                setAnswers([...answers, newAnswer]);
                // set next question
                if (selected.nextQuestionId) {
                    setQuestion(getQuestion(selected.nextQuestionId));
                } else {
                    setIsComplete(true);
                }
            } else if (question.defaultNextQuestionId) {
                setQuestion(getQuestion(question.defaultNextQuestionId));
            } else {
                setIsComplete(true);
            }
        }
        setSelected(undefined);
    };

    if (isSubmitted) return <Success />;

    return (
        <section className="quiz">
            <div>
                {isComplete ? (
                    id ? (
                        <Thanks />
                    ) : (
                        <Submit onSubmit={setIsSubmitted} />
                    )
                ) : (
                    <>
                        <h1 className="question">{question?.text}</h1>
                        <div className="options">
                            {getOptions(question?.optionIds).map((e) => {
                                return (
                                    <Option
                                        key={e.id}
                                        option={e}
                                        handleSelect={setSelected}
                                        isSelected={e.id === selected?.id}
                                    />
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
            {!isComplete && (
                <nav className="quiz-nav">
                    <button onClick={handleNext} className="next-button">
                        Continue
                    </button>
                    <button onClick={handleBack} className="back-button">
                        Back
                    </button>
                </nav>
            )}
        </section>
    );
}
