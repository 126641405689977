import { IOption } from "../types";

interface Props {
    option: IOption;
    handleSelect: React.Dispatch<React.SetStateAction<IOption | undefined>>;
    isSelected: boolean;
}

export default function Option({ option, handleSelect, isSelected }: Props) {
    return (
        <button
            className={`option ${isSelected ? "active" : ""}`}
            onClick={() => handleSelect(option)}
        >
            <img src={`/${option.img}`} className="option-img" alt="" />
            {option.text}
        </button>
    );
}
