export default function Header() {
    return (
        <header className="header">
            <a href="/">
                <span className="logo-text">GiftQuiz</span>
            </a>
            {/* <button
                id="menu-open-button"
                aria-expanded="false"
                aria-controls="menu"
            >
                <svg width="16" height="10" viewBox="0 0 16 10">
                    <title>Open mobile navigation</title>
                    <g fill="#ffffff" fillRule="evenodd">
                        <rect y="8" width="16" height="2" rx="1"></rect>
                        <rect y="4" width="16" height="2" rx="1"></rect>
                        <rect width="16" height="2" rx="1"></rect>
                    </g>
                </svg>
            </button> */}
        </header>
    );
}
